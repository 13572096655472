<template>
  <section class="feed-new">
    <div class="swiper dosiin_feed_slider feed_slider dosiin_position-unset">
      <div class="swiper-wrapper overflow">
        <div
          class="swiper-slide"
          v-for="i in 8"
          :key="i"
          style="
            width: 120px;
            height: 190px;
            border-radius: 8px;
            overflow: hidden;
            margin-right: 10px;
          "
        >
          <Skeletor width="120" height="190" />
          <div
            class="desc"
            style="
              position: absolute;
              bottom: 8px;
              left: 8px;
              width: calc(100% - 16px);
              z-index: 10;
            "
          >
            <div class="name"><Skeletor hieght="24" width="100%" /></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
