<template>
  <section class="feed-new" :class="layout != 4 ? 'feed-new-pc' : 'dosiin_mb-2'">
    <div class="header-section">
      <h2 class="header-section-left-side section-title">{{ $t("style.top_style") }}</h2>
      <div class="dosiin_slider-controls">
        <div class="dosiin_pagination-brand-img swipable-slider_pagination"></div>
      </div>
    </div>
    <div :class="layout != 4 ? 'dosiin_position-relative' : ''">
      <TopSkeleton v-if="state.loading" ref="topSkeleton"></TopSkeleton>
      <Swiper
        v-else
        :modules="modules"
        :slideToClickedSlide="swiperOptions.slideToClickedSlide"
        :watchSlidesVisibility="swiperOptions.watchSlidesVisibility"
        :watchSlidesProgress="swiperOptions.watchSlidesProgress"
        :breakpoints="swiperOptions.breakpoints"
        class="dosiin_feed_slider feed_slider"
        :class="layout != 4 ? 'dosiin_position-unset' : ''"
      >
        <Swiper-slide
          v-for="(post, index) in state.posts"
          :key="index"
          :virtualIndex="index"
        >
          <router-link :to="_vnfdUrl(post.seo_name)" class="dosiin_w-100">
            <div
              class="feed-item"
              v-bind:style="{
                background:
                  'url(' + _postThumbnail(post.main_pair) + ') no-repeat center center',
                backgroundSize: 'cover',
              }"
            >
              <div class="desc">
                <div
                  v-if="post.brand_id && post.brand_id != '0'"
                  class="name truncate"
                  v-text="post.company_name"
                ></div>
                <div v-else class="name truncate" v-text="post.user_name"></div>
              </div>
            </div>
          </router-link>
        </Swiper-slide>
        <div
          v-if="layout != 4"
          class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev"
        ></div>
        <div
          v-if="layout != 4"
          class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"
        ></div>
      </Swiper>
    </div>
  </section>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import { Pagination, Navigation } from "swiper";
import TopSkeleton from "@/components/skeletons/TopTrending.vue";
import StyleService from "@/services/style";

export default {
  name: "top-trending-post",
  components: {
    TopSkeleton,
  },
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      posts: [],
      loading: true,
    });

    const swiperOptions = {
      slideToClickedSlide: false,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        320: {
          slidesPerView: 3.5,
          spaceBetween: 9,
        },
        769: {
          slidesPerView: 8,
          spaceBetween: 8,
          slidesPerGroup: 8,
          pagination: {
            el: ".dosiin_pagination-brand-img",
            clickable: true,
            renderBullet: (index, className) =>
              `<div data="${index}" class="swipable-slider_indicator ${className}"></div>`,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
      },
    };

    getPosts();

    async function getPosts() {
      const response = await StyleService.fetchPosts({
        getTop: true,
        sort_by: "timestamp",
      });
      const postData = response.data;
      state.posts = postData["vnfd"] ? postData["vnfd"] : [];
      state.loading = false;
    }

    return {
      state,
      swiperOptions,
      modules: [Pagination, Navigation],
    };
  },
};
</script>
